// import '../styles/globals.css'
import React from 'react';

export interface MyAppProps {
  Component: any;
  pageProps: any;
}

import {
  IonApp,
} from "@ionic/react";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {

}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // You can also log the error to an error reporting service
    console.log('Error catched in', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div>Error</div>;
    }

    return this.props.children; 
  }
}

const MyApp = ({ Component, pageProps }: MyAppProps) => {
  return (
    <ErrorBoundary>
      <IonApp>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap" rel="stylesheet" />

        <Component {...pageProps} />
      </IonApp>
    </ErrorBoundary>
  );
};

export default MyApp;